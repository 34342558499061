import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/beadswapfrontend/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Swapping is a kind of process in which, A user picks an input token and get an output token. They specify an input amount, and the Beadswap protocol automatically calculates how much of the output tokens user will receive. They then execute the swap by clicking the “Swap” button, and immediately user receives the output token in the wallet.`}</p>
    <p>{`Swapping the BEP-20 Token is quite easy in Beadswap Protocol.`}</p>
    <p>{`Beadswap protocol is a decentralized trading platform. Beadswap does not use an order book to represent liquidity or determine prices. Beadswap uses an automated market maker mechanism to provide instant feedback on rates and slippage.`}</p>
    <p>{`In this guide, we’ll look at what happens during a swap at the Beadswap protocol level to gain a deeper understanding of how Beadswap works.`}</p>
    <p>{`This rule is the `}<a parentName="p" {...{
        "href": "/docs/v1/protocol-overview/glossary#constant-product-formula"
      }}>{`constant product formula`}</a>{`. When either token is withdrawn (purchased), a proportional amount of the other must be deposited (sold), to maintain the constant.`}</p>
    <p>{`As we learned in `}<a parentName="p" {...{
        "href": "/docs/v1/protocol-overview/how-beadswap-works"
      }}>{`Protocol Overview`}</a>{`, each pair on Beadswap is actually underpinned by a liquidity pool. Smart contracts are used to maintain the Liquidity Pools of different Tokens.`}</p>
    <h2 {...{
      "id": "anatomy-of-a-swap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#anatomy-of-a-swap",
        "aria-label": "anatomy of a swap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Anatomy of a swap`}</h2>
    <p>{`In Beadswap, all the Swaps are get done by calling the single function of `}<inlineCode parentName="p">{`swap`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swap(uint amount0Out, uint amount1Out, address to, bytes calldata data);
`}</code></pre>
    <h1 {...{
      "id": "receiving-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#receiving-tokens",
        "aria-label": "receiving tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Receiving tokens`}</h1>
    <p>{`As is probably clear from the function signature, Beadswap requires `}<inlineCode parentName="p">{`swap`}</inlineCode>{` callers to `}<em parentName="p">{`specify how many output tokens they would like to receive`}</em>{` via the `}<inlineCode parentName="p">{`amount{0,1}Out`}</inlineCode>{` parameters, which correspond to the desired amount of `}<inlineCode parentName="p">{`token{0,1}`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "sending-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#sending-tokens",
        "aria-label": "sending tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending Tokens`}</h1>
    <p>{`What’s not as clear is how Beadswap `}<em parentName="p">{`receives`}</em>{` tokens as payment for the swap. Typically, smart contracts which need tokens to perform some functionality require callers to first make an approval on the token contract, then call a function that in turn calls transferFrom on the token contract. This is `}<em parentName="p">{`not`}</em>{` how pairs accept tokens. Instead, pairs check their token balances at the `}<em parentName="p">{`end`}</em>{` of every interaction. Then, at the beginning of the `}<em parentName="p">{`next`}</em>{` interaction, current balances are differenced against the stored values to determine the amount of tokens that were sent by the current interactor. See the `}<a href='#' rel='noopener noreferrer'>{`whitepaper`}</a>{` for a justification of why this is the case.`}</p>
    <h1 {...{
      "id": "developer-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developer-resources",
        "aria-label": "developer resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developer resources`}</h1>
    <ul>
      <li parentName="ul">{`To see how to implement token swaps in a smart contract read `}<a parentName="li" {...{
          "href": "/docs/v1/smart-contract-integration/trading-from-a-smart-contract/"
        }}>{`Trading from a smart contract`}</a>{`.`}</li>
      <li parentName="ul">{`To see how to execute a swap from an interface read `}<a parentName="li" {...{
          "href": "/docs/v1/javascript-SDK/trading/"
        }}>{`Trading (SDK)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      